<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
    <div class="container mx-auto">
      <h1
        class="flex items-center font-sans font-bold break-normal text-indigo-500  text-xl md:text-2xl py-2"
      >
        YTD Sales
      </h1>

      <div class="mb-4">
        <!-- component -->
        <div id="recipients" class="">
          <table id="tn-tickets" class="border-collapse w-full text-sm">
            <thead>
              <tr>
                <th
                  data-priority="1"
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell"
                >
                  Event
                </th>
                <th
                  data-priority="2"
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell"
                >
                  City
                </th>
                <th
                  data-priority="3"
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Available Tixs
                </th>
                <th
                  data-priority="4"
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Dates
                </th>
                <th
                  data-priority="5"
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell"
                >
                  YTD
                </th>
                <th
                  data-priority="6"
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell"
                >
                  3MON
                </th>
                <th
                  data-priority="7"
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell"
                >
                  1MON
                </th>
                <th
                  data-priority="8"
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell"
                >
                  WEEK
                </th>
                <th
                  data-priority="9"
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell"
                >
                  3DAY
                </th>
                <th
                  data-priority="10"
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell"
                >
                  TODAY
                </th>
                <th
                  data-priority="11"
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell"
                >
                  6Hour
                </th>
              </tr>
            </thead>
            <tbody id="ticketsList">
              <tr
                v-if="!data.length"
                class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
              >
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                  colspan="12"
                >
                  {{ loading }}
                </td>
              </tr>
              <tr
                v-for="tr in data"
                :key="tr.id"
                class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
                id="tn-tix-data-wait"
              >
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                >
                  {{ tr.event }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                >
                  {{ tr.city }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                >
                  {{ tr.availabletixs }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                >
                  {{ tr.dates }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                >
                  {{ tr.ytd }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                >
                  {{ tr.ThreeMonth }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                >
                  {{ tr.OneMonth }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                >
                  {{ tr.OneWeek }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                >
                  {{ tr.ThreeDay }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                >
                  {{ tr.Today }}
                </td>
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static"
                >
                  {{ tr.SixHour }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "NewTickets",
  data() {
    return {
      loading: "Please wait...",
      data: []
    };
  },
  created() {
    this.get();
  },
  methods: {
    get() {
      this.$axios
        .get(`${this.$api}/ticket/tn-tix`)
        .then(res => {
          this.data = res.data.data;
          if (this.data.length === 0) {
            this.loading = "No data found";
          }
          if (!res.data.success) {
            this.loading = "No data found";
          }
          var self = this;
          var existTnTix = setInterval(function() {
            if (self.jQuery("#tn-tix-data-wait").length) {
              clearInterval(existTnTix);
              self.jQuery("#tn-tickets").DataTable({
                responsive: true,
                aLengthMenu: [100],
                // aaSorting: [[5, "desc"]],
                filter: false,
                ordering: false,
                processing: true,
                language: {
                  processing:
                    "<i class='fa fa-refresh fa-spin' style='font-size:4em;margin-top:20%;'></i>"
                },
                serverSide: true,
                ajax: {
                  url: `${self.$api}/ticket/tn-tix`,
                  dataFilter: function(data) {
                    const records = JSON.parse(data);
                    const json = records.data;
                    return JSON.stringify({
                      recordsTotal: records.total,
                      recordsFiltered: records.total,
                      data: json
                    }); // return JSON string
                  }
                },
                deferLoading: res.data.total,
                columns: [
                  {
                    data: "event",
                    title: "Event",
                    render: function(data, _type, row) {
                      return `<a
                        class="cursor-pointer text-blue-600"
                        href="/admin/ticket-chart/${data}/${row.city}"
                        target="_blank"
                        >${data}</a
                      >`;
                    }
                  },
                  { data: "city" },
                  { data: "availabletixs" },
                  { data: "dates" },
                  { data: "ytd" },
                  { data: "ThreeMonth" },
                  { data: "OneMonth" },
                  { data: "OneWeek" },
                  { data: "ThreeDay" },
                  { data: "Today" },
                  { data: "SixHour" }
                ]
              });
            }
          }, 100);
        })
        .catch(err => {
          console.log(err);
          this.loading = "Something went wrong, please try again";
        });
    }
  }
};
</script>
